<template>
  <div class="reportForRepair">
    <div v-if="repairRoleList.length!==1"
         class="radio-group">
      <el-radio-group v-model="tableType">
        <el-radio-button v-for="repairRole in  repairRoleList"
                         :key="repairRole.id"
                         :label="repairRole.id">{{repairRole.name}}</el-radio-button>
      </el-radio-group>
    </div>
    <div class="header">
      <div>
        <el-button type="success"
                   v-if="tableType===1"
                   icon="el-icon-plus"
                   @click="addApplication">添加申请</el-button>
      </div>
      <div>
        <el-select v-model="queryEquipmentType"
                   clearable
                   class="header-select"
                   placeholder="请选择维修类型">
          <el-option v-for="type in equipmentTypeList"
                     :key="type.value"
                     :label="type.name"
                     :value="type.value"></el-option>
        </el-select>
        <el-select v-model="queryState"
                   clearable
                   class="header-select"
                   placeholder="请选择维修状态">
          <el-option v-for="state in stateList"
                     :key="state.value"
                     :label="state.name"
                     :value="state.value"></el-option>
        </el-select>
        <el-button type="primary"
                   @click="loadReportForRepair(1)">查询</el-button>
      </div>
    </div>
    <el-table :data="reportForRepairList">
      <el-table-column label="设备类型"
                       align="center"
                       prop="deviceStr"></el-table-column>
      <el-table-column label="设备名称"
                       align="center"
                       prop="name"></el-table-column>
      <el-table-column label="报修数量"
                       align="center"
                       prop="count"></el-table-column>
      <el-table-column label="维修位置"
                       align="center"
                       prop="position"></el-table-column>
      <el-table-column label="维修工人"
                       align="center"
                       prop="driver"></el-table-column>
      <el-table-column label="维修工人电话"
                       align="center"
                       prop="driver_mobile"></el-table-column>
      <el-table-column label="审核人"
                       align="center"
                       prop="reviewer"></el-table-column>
      <el-table-column label="申报人"
                       prop="operator"
                       align="center"></el-table-column>
      <el-table-column label="申报人电话"
                       prop="mobile"
                       align="center"></el-table-column>
      <el-table-column label="状态"
                       align="center"
                       prop="statusStr"></el-table-column>
      <el-table-column label="操作"
                       align="center"
                       width="210px">
        <template slot-scope="scope">
          <el-button size="mini"
                     v-if="tableType===1"
                     @click="detail(scope.row,1)"
                     type="warning">详情</el-button>
          <el-button size="mini"
                     v-if="tableType===1"
                     :disabled="scope.row.status!==0"
                     @click="detail(scope.row,2)"
                     type="primary">编辑</el-button>
          <el-button size="mini"
                     v-if="tableType===1"
                     :disabled="scope.row.status!==0"
                     @click="deleteApplication(scope.row.id)"
                     type="dange">撤回</el-button>
          <el-button v-if="tableType===2"
                     :disabled="scope.row.status!==0"
                     @click="detail(scope.row,3)"
                     type="primary">审核</el-button>
          <el-button v-if="tableType===3"
                     :disabled="scope.row.status===3"
                     @click="detail(scope.row,4)"
                     type="primary">完成情况确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadReportForRepair"></el-pagination>
    <el-dialog width="600px"
               :title="addApplicationForm.id?'审核详情':'添加申请'"
               :visible.sync="addReportForRepairVisible">
      <el-form ref="addApplicationForm"
               :model="addApplicationForm"
               :rules="addApplicationFormRule"
               label-width="110px">
        <el-form-item label="设备类型"
                      prop="equipmentType">
          <el-select v-model="addApplicationForm.equipmentType"
                     :disabled="formDisabled">
            <el-option v-for="type in equipmentTypeList"
                       :key="type.value"
                       :label="type.name"
                       :value="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称"
                      prop="equipmentName">
          <el-input v-model="addApplicationForm.equipmentName"
                    :disabled="formDisabled"></el-input>
        </el-form-item>
        <el-form-item label="报修数量"
                      prop="equipmentNumber">
          <el-input v-model="addApplicationForm.equipmentNumber"
                    :disabled="formDisabled"></el-input>
        </el-form-item>
        <el-form-item label="报修位置"
                      prop="equipmentPostion">
          <el-input v-model="addApplicationForm.equipmentPostion"
                    :disabled="formDisabled"></el-input>
        </el-form-item>
        <el-form-item label="申报人电话"
                      prop="declarantTelePhone">
          <el-input v-model="addApplicationForm.declarantTelePhone"
                    :disabled="formDisabled"></el-input>
        </el-form-item>
        <el-form-item label="具体说明"
                      prop="specificDescription">
          <el-input v-model="addApplicationForm.specificDescription"
                    type="textarea"
                    :disabled="formDisabled"
                    :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="审批人"
                      prop="reviewer">
          <el-select v-model="addApplicationForm.reviewer"
                     :disabled="formDisabled">
            <el-option v-for="reviewer in reviewerList"
                       :key="reviewer.managerid"
                       :label="reviewer.mname"
                       :value="reviewer.managerid"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="buttonType===1||buttonType===3">
          <hr style="border : 1px solid rgb(187,187,187);" />
          <el-form-item label="维修工人"
                        prop="repairman">
            <el-select v-model="addApplicationForm.repairman"
                       clearable
                       @change="repairmanChange"
                       :disabled="tableType===2&&addApplicationForm.state!==0||buttonType===1">
              <el-option v-for="repairman in repairmanList"
                         :key="repairman.managerid"
                         :label="repairman.mname"
                         :value="repairman.managerid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="维修工人电话"
                        prop="repairmanTelephone">
            <el-input v-model="addApplicationForm.repairmanTelephone"
                      :disabled="tableType===2&&addApplicationForm.state!==0||buttonType===1"></el-input>
          </el-form-item>
          <el-form-item label="意见">
            <el-input v-model="addApplicationForm.option"
                      :disabled="tableType===2&&addApplicationForm.state!==0||buttonType===1"></el-input>
          </el-form-item>
        </div>
        <div v-if="buttonType===4">
          <hr style="border : 1px solid rgb(187,187,187);" />
          <el-form-item label="备注">
            <el-input v-model="addApplicationForm.remark"
                      :disabled="addApplicationForm.state===3"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer">
        <el-button @click="addReportForRepairVisible=false"
                   v-if="buttonType===0 || buttonType===2">取消</el-button>
        <el-button type="primary"
                   v-if="buttonType===0 || buttonType===2"
                   @click="confirmAddApplication">确定</el-button>
        <el-button type="primary"
                   v-if="buttonType===3 && addApplicationForm.state===0"
                   @click="correctionApplication(1)">同意</el-button>
        <el-button type="primary"
                   v-if="buttonType===3 && addApplicationForm.state===0"
                   @click="correctionApplication(2)">拒绝</el-button>
        <el-button type="primary"
                   v-if="buttonType===4 && addApplicationForm.state!==3"
                   @click="finishApplication">完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "reportForRepair",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      tableType: 1,
      buttonType: 0, //0申请，1详情，2编辑,3审核,4完成情况确认
      page: { pageCount: 1, currentPage: 0 },
      reportForRepairList: [],
      repairmanList: [], //修理工
      reviewerList: [], //审批人
      addReportForRepairVisible: false,
      addApplicationForm: {
        equipmentType: "",
        equipmentName: "",
        equipmentNumber: "",
        equipmentPostion: "",
        declarantTelePhone: "",
        specificDescription: "",
        reviewer: -1,
        repairman: -1,
        repairmanTelephone: "",
        option: "",
        state: "", //0未受理，1已受理，2已拒绝，3已修理，4撤回
        remark: "",
      },
      addApplicationFormRule: {
        equipmentType: [
          { required: true, message: "请输入设备类型", trigger: "blur" },
        ],
        equipmentName: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
        equipmentNumber: [
          { required: true, message: "请输入设备数量", trigger: "blur" },
        ],
        equipmentPostion: [
          { required: true, message: "请输入报修位置", trigger: "blur" },
        ],
        declarantTelePhone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        // specificDescription: [
        //   { required: true, message: "请输入描述", trigger: "blur" },
        // ],
        reviewer: [
          { required: true, message: "请选择审批人", trigger: "blur" },
        ],
        repairman: [
          { required: true, message: "请选择维修工", trigger: "blur" },
        ],
        repairmanTelephone: [
          { required: true, message: "请输入维修工电话", trigger: "blur" },
        ],
        option: [{ required: true, message: "请输入意见", trigger: "blur" }],
      },
      equipmentTypeList: [
        { name: "水电维修", value: 1 },
        { name: "电器维修安装", value: 2 },
        { name: "电器保养", value: 3 },
        { name: "管道疏通", value: 4 },
        { name: "其他", value: 5 },
      ],
      repairRoleList: [],
      queryEquipmentType: "",
      queryState: "",
      stateList: [
        { name: "未受理", value: 0 },
        { name: "已受理", value: 1 },
        { name: "拒绝", value: 2 },
        { name: "已维修", value: 3 },
        { name: "撤回", value: 4 },
        { name: "全部", value: 5 },
      ],
      formDisabled: false,
    };
  },
  watch: {
    tableType: function (newQuestion) {
      this.loadReportForRepair(1);
    },
  },
  created() {
    this.loadRepairRole();
    this.loadReportForRepair(1);
    this.loadRepairman();
  },
  methods: {
    loadRepairRole() {
      const that = this;
      this.$post("/base/queryRepairRole.do", {})
        .then((res) => {
          that.repairRoleList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "danger",
            message: err.message,
          });
        });
    },
    loadReportForRepair(page) {
      const that = this;
      let data = { indexNo: page, module_type: this.tableType };
      if (this.queryState !== 4) {
        data.status = this.queryState;
      } else {
        data.status = "";
      }
      if (this.queryEquipmentType) {
        data.device_type = this.queryEquipmentType;
      }
      this.$post("/base/queryRepair.do", data)
        .then((res) => {
          that.page.currentPage = res.data.indexNo;
          that.page.pageCount = res.data.pageCount;
          res.data.rows.forEach((row) => {
            if (row.device_type === 1) {
              row.deviceStr = "水电维修";
            } else if (row.device_type === 2) {
              row.deviceStr = "电器维修安装";
            } else if (row.device_type === 3) {
              row.deviceStr = "电器保养";
            } else if (row.device_type === 4) {
              row.deviceStr = "管道疏通";
            } else {
              row.deviceStr = "其他";
            }
          });
          that.reportForRepairList = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "danger",
            message: err.message,
          });
        });
    },
    loadRepairman() {
      const that = this;
      this.$post("/base/queryApprover.do", { type: 4 }) //4是维修人员
        .then((res) => {
          //   that.addApplicationForm.reviewer = res.data[0].id;
          that.repairmanList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "danger",
            message: err.message,
          });
        });
      this.$post("/base/queryApprover.do", { type: 2 }) //2报修审批人
        .then((res) => {
          if (res.data.length !== 0) {
            that.addApplicationForm.reviewer = res.data[0].managerid;
          }
          that.reviewerList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "danger",
            message: err.message,
          });
        });
    },
    addApplication() {
      this.buttonType = 0;
      this.formDisabled = false;
      this.addReportForRepairVisible = true;
      this.$nextTick(() => {
        this.$refs["addApplicationForm"].resetFields();
        this.addApplicationForm.declarantTelePhone = this.userInfo.usermobile;
        this.addApplicationForm.id = "";
        this.addApplicationForm.state = "";
      });
    },
    confirmAddApplication() {
      this.$refs["addApplicationForm"].validate((valid) => {
        if (valid) {
          const that = this;
          let data = {
            device_type: this.addApplicationForm.equipmentType,
            name: this.addApplicationForm.equipmentName,
            count: this.addApplicationForm.equipmentNumber,
            position: this.addApplicationForm.equipmentPostion,
            content: this.addApplicationForm.specificDescription,
            reviewerid: this.addApplicationForm.reviewer,
            tea_mobile: this.addApplicationForm.declarantTelePhone,
          };
          let apiUrl = "/base/insertRepair.do";
          if (this.addApplicationForm.id) {
            data.id = this.addApplicationForm.id;
            apiUrl = "/base/updateRepair.do";
          }
          this.$confirm("是否确认提交该申请？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.$message({ type: "success", message: res.message });
                  that.loadReportForRepair(1);
                  this.addReportForRepairVisible = false;
                })
                .catch((err) => {
                  this.addReportForRepairVisible = false;
                  that.$message({ type: "warning", message: err.message });
                });
            })
            .catch(() => {
              that.$message({ type: "warning", message: "已取消确认" });
            });
        } else {
          return false;
        }
      });
    },
    //批改申请
    correctionApplication(state) {
      let data = {
        id: this.addApplicationForm.id,
        status: state,
        fbcontent: this.addApplicationForm.option,
      };
      if (state === 1) {
        data.driverid = this.addApplicationForm.repairman;
        data.driver_mobile = this.addApplicationForm.repairmanTelephone;
      }
      this.$confirm("是否确认提交该申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/auditRepair.do", data)
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.loadReportForRepair(1);
              this.addReportForRepairVisible = false;
            })
            .catch((err) => {
              this.addReportForRepairVisible = false;
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消确认" });
        });
    },
    detail(row, buttonType) {
      if (buttonType !== 2) {
        this.formDisabled = true;
      } else {
        this.formDisabled = false;
      }
      this.addReportForRepairVisible = true;
      this.$nextTick(() => {
        this.addApplicationForm.id = row.id;
        this.addApplicationForm.equipmentType = row.device_type;
        this.addApplicationForm.equipmentName = row.name;
        this.addApplicationForm.equipmentNumber = row.count;
        this.addApplicationForm.equipmentPostion = row.position;
        this.addApplicationForm.declarantTelePhone = row.tea_mobile;
        this.addApplicationForm.specificDescription = row.content;
        this.addApplicationForm.reviewer = row.reviewerid;
        this.addApplicationForm.state = row.status;
        this.addApplicationForm.repairman = row.driverid;
        this.addApplicationForm.repairmanTelephone = row.driver_mobile;
        this.addApplicationForm.option = row.fbcontent;
        this.addApplicationForm.remark = row.repair_details;
      });
      this.buttonType = buttonType;
    },
    deleteApplication(id) {
      this.$confirm("是否确认撤回该申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/cancelRepair.do", { id })
            .then((res) => {
              this.loadReportForRepair(1);
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消撤回" });
        });
    },
    finishApplication() {
      this.$confirm("是否确认核对完成？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/endRepair.do", {
            id: this.addApplicationForm.id,
            repair_details: this.addApplicationForm.remark,
          })
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.addReportForRepairVisible = false;
              this.loadReportForRepair(1);
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消撤回" });
        });
    },
    repairmanChange(event) {
      for (let i = 0; i < this.repairmanList.length; i++) {
        if (this.repairmanList[i].managerid === event) {
          this.addApplicationForm.repairmanTelephone =
            this.repairmanList[i].mobile;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
}
/deep/.header > div > .el-input {
  margin-right: 10px;
}
.header-select {
  margin: 0 10px;
}
.radio-group {
  margin-bottom: 20px;
}
</style>
